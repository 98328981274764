/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const Component1 = ({
  property1,
  className,
  overlapGroupClassName,
  rectangleClassName,
  DSCCopyClassName,
  DSCCopy = "/img/dsc-6340-copy-3-2.png",
  DSCCopyClassNameOverride,
  img = "/img/dsc-6340-copy-2-2.png",
  IMGClassName,
  IMG = "/img/img-5892-1-2.png",
  IMGClassNameOverride,
  IMG1 = "/img/img-5892-2-1.png",
  imgClassName,
  IMG2 = "/img/img-5892-3-1.png",
  imgClassNameOverride,
  IMG3 = "/img/img-5892-4-1.png",
  frameClassName,
  garmentAgeingClassName,
  divClassName,
  divClassNameOverride,
}) => {
  return (
    <div className={`component-1 ${className}`}>
      <div className={`overlap-group-3 ${overlapGroupClassName}`}>
        <div className={`rectangle-2 ${rectangleClassName}`} />
        <img
          className={`DSC-copy-5 ${DSCCopyClassName}`}
          alt="Dsc copy"
          src={property1 === "frame-163005" ? "/img/dsc-6340-copy-3-1.png" : DSCCopy}
        />
        <img
          className={`DSC-copy-6 ${DSCCopyClassNameOverride}`}
          alt="Dsc copy"
          src={property1 === "frame-163005" ? "/img/dsc-6340-copy-2-1.png" : img}
        />
        <img
          className={`IMG ${IMGClassName}`}
          alt="Img"
          src={property1 === "frame-163005" ? "/img/img-5892-1-1.png" : IMG}
        />
        <img className={`IMG-2 ${IMGClassNameOverride}`} alt="Img" src={IMG1} />
        <img className={`IMG-3 ${imgClassName}`} alt="Img" src={IMG2} />
        <img className={`IMG-4 ${imgClassNameOverride}`} alt="Img" src={IMG3} />
        <div className={`frame-10 ${frameClassName}`}>
          <div className={`rasum ${property1} ${garmentAgeingClassName}`}>
            {property1 === "frame-163004" && <>Rasum</>}

            {property1 === "frame-163005" && <>Garment Ageing</>}
          </div>
          <div className={`a-day-in-the-life-of property-1-${property1} ${divClassName}`}>
            {property1 === "frame-163004" && <p className="text-wrapper-130">A day in the life of two sisters</p>}

            {property1 === "frame-163005" && <> Way of Fabric manipulation.</>}
          </div>
        </div>
        <div className={`text-wrapper-21 ${divClassNameOverride}`}>EDITORIAL SHOOT</div>
      </div>
    </div>
  );
};

Component1.propTypes = {
  property1: PropTypes.oneOf(["frame-163004", "frame-163005"]),
  DSCCopy: PropTypes.string,
  img: PropTypes.string,
  IMG: PropTypes.string,
  IMG1: PropTypes.string,
  IMG2: PropTypes.string,
  IMG3: PropTypes.string,
};
