import React, { useEffect } from "react";
import { Component } from "../../components/Component";
import { Component1 } from "../../components/Component1";
import { Component10 } from "../../components/Component10";
import { Component11 } from "../../components/Component11";
import { Component12 } from "../../components/Component12";
import { Component13 } from "../../components/Component13";
import { Component14 } from "../../components/Component14";
import { Component26 } from "../../components/Component26";
import { Component33 } from "../../components/Component33";
import { Component9 } from "../../components/Component9";
import { DivWrapper } from "../../components/DivWrapper";
import { PropertyComponentWrapper } from "../../components/PropertyComponentWrapper";
import { PropertyFrameWrapper } from "../../components/PropertyFrameWrapper";
import "./style.css";

export const VykhariCaseStudy = () => {
  useEffect(() => {
    document.body.style.transform = `scale(${window.innerWidth / 1933})`;
    document.body.style.transformOrigin = `top left`;
  });
  return (
    <div className="vykhari-case-study">
      <div className="div-2">
        <div className="overlap">
          <div className="rectangle-3" />
          <div className="the-challenge">
            <div className="frame-wrapper">
              <div className="frame-11">
                <div className="text-wrapper-22">About:</div>
                <div className="frame-12">
                  <p className="in-a-world-where">
                    In a world where first impressions matter, Vykhari - a
                    visually stunning website stands as a testament to the power
                    of design and storytelling.&nbsp;&nbsp;Immerse yourself in a
                    gallery of imagery that tells tales of styling, passion, and
                    dedication.
                  </p>
                  <p className="text-wrapper-23">
                    Interactions on this website are an adventure in themselves.
                    As you scroll, hover, and explore, animations and
                    transitions guide your path with fluid elegance.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="final">
            <div className="overlap-2">
              <div className="rectangle-4" />
              <Component className="component-instance" />
              <PropertyComponentWrapper
                className="component-27-instance"
                property1="component-25"
                rectangle="/img/rectangle-387.png"
                rectangleClassName="design-component-instance-node"
              />
              <img
                className="rectangle-5"
                alt="Rectangle"
                src="/img/rectangle-388.png"
              />
              <div className="rectangle-6" />
              <img className="xa" alt="Xa" src="/img/x5a0432-1.png" />
              <div className="rectangle-7" />
              <div className="frame-13">
                <div className="text-wrapper-24">Product Styling</div>
              </div>
              <p className="text-wrapper-25">
                Concept to photography, we create visually stunning imagery that
                captivates your audience.
              </p>
              <div className="frame-14">
                <div className="frame-15">
                  <div className="text-wrapper-26">Personal Styling</div>
                  <p className="text-wrapper-27">
                    We create bespoke looks that reflect your individuality.
                    Styling for Weddings, Parties or any occasion.
                  </p>
                </div>
                <div className="group-9">
                  <div className="text-wrapper-28">Learn more</div>
                  <div className="ellipse-4" />
                </div>
              </div>
              <div className="group-10">
                <div className="text-wrapper-29">Learn more</div>
                <div className="ellipse-5" />
              </div>
              <div className="frame-16">
                <div className="frame-17">
                  <div className="text-wrapper-30">
                    Traditional Hindu Wedding
                  </div>
                  <p className="text-wrapper-31">
                    Add grandeur to your special day. From timeless sarees to
                    regal sherwanis, we create bespoke ensembles that reflect
                    your unique style.
                  </p>
                </div>
                <div className="group-11">
                  <div className="text-wrapper-28">Learn more</div>
                  <div className="ellipse-6" />
                </div>
              </div>
              <div className="frame-18">
                <div className="frame-15">
                  <div className="commercial-styling-e">
                    Commercial Styling/ <br />
                    E-commerce Styling
                  </div>
                  <p className="text-wrapper-27">
                    Elevate your online store with our Commercial Styling and
                    E-commerce services. From concept to photography, we create
                    stunning visuals that drive sales.
                  </p>
                </div>
                <div className="group-11">
                  <div className="text-wrapper-28">Learn more</div>
                  <div className="ellipse-6" />
                </div>
              </div>
            </div>
            <p className="text-wrapper-32">
              Why educators and families love kreedo
            </p>
            <div className="overlap-3">
              <PropertyFrameWrapper
                className="component-22"
                property1="frame-162972"
              />
              <img
                className="rectangle-8"
                alt="Rectangle"
                src="/img/rectangle-384.png"
              />
              <div className="overlap-4">
                <div className="group-12">
                  <div className="group-13">
                    <div className="text-wrapper-33">CULTURE</div>
                    <div className="frame-19">
                      <img
                        className="vector-2"
                        alt="Vector"
                        src="/img/vector-1-1.svg"
                      />
                      <div className="frame-20">
                        <p className="text-wrapper-34">
                          Where History and Culture Inspire Fashion.
                        </p>
                      </div>
                    </div>
                  </div>
                  <DivWrapper
                    className="component-25"
                    property1="frame-162983"
                  />
                </div>
                <p className="text-wrapper-35">
                  Reviving the Past, Redefining the Future
                </p>
              </div>
              <div className="header">
                <img
                  className="vykhari"
                  alt="Vykhari"
                  src="/img/vykhari-1-4.png"
                />
                <div className="frame-21">
                  <div className="frame-22">
                    <div className="text-wrapper-36">About Us</div>
                  </div>
                  <div className="frame-23">
                    <div className="text-wrapper-37">Services</div>
                  </div>
                  <div className="frame-24">
                    <div className="text-wrapper-38">Work</div>
                  </div>
                  <div className="frame-25">
                    <div className="text-wrapper-39">Contact us</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="group-14">
              <div className="frame-26">
                <div className="overlap-5">
                  <img
                    className="rectangle-9"
                    alt="Rectangle"
                    src="/img/rectangle-366.png"
                  />
                  <img className="xa-2" alt="Xa" src="/img/x5a0505-9.png" />
                  <div className="text-wrapper-40">01/</div>
                  <div className="text-wrapper-41">02/</div>
                  <div className="text-wrapper-42">FUCHSIA BY RITU</div>
                  <img className="line" alt="Line" src="/img/line-1.svg" />
                </div>
                <div className="overlap-group-4">
                  <div className="text-wrapper-43">03/</div>
                </div>
              </div>
            </div>
            <div className="frame-27">
              <div className="text-wrapper-44">Vykhari, so far</div>
              <div className="frame-28">
                <div className="text-wrapper-45">Contact us</div>
              </div>
            </div>
            <div className="overlap-6">
              <p className="text-wrapper-46">
                Blend your culture with modern world
              </p>
            </div>
            <div className="frame-29">
              <div className="text-wrapper-47">Our Portfolio</div>
              <div className="frame-28">
                <div className="text-wrapper-45">View all</div>
              </div>
            </div>
            <div className="overlap-7">
              <img className="IMG-5" alt="Img" src="/img/img-5242-1-4.png" />
              <div className="DSC-wrapper">
                <img className="DSC" alt="Dsc" src="/img/dsc-6028-1-1.png" />
              </div>
              <img className="image" alt="Image" src="/img/image-16.png" />
              <div className="img-wrapper">
                <img className="DSC" alt="Dsc" src="/img/dsc-6028-1.png" />
              </div>
              <p className="experience-the-power">
                <span className="text-wrapper-48">Experience the </span>
                <span className="text-wrapper-49">Powe</span>
                <span className="text-wrapper-50">r</span>
                <span className="text-wrapper-48">
                  {" "}
                  of <br />a
                </span>
                <span className="text-wrapper-51">&nbsp;</span>
                <span className="text-wrapper-52">Personal Stylist.</span>
              </p>
              <div className="frame-30">
                <div className="text-wrapper-39">Contact us</div>
              </div>
            </div>
            <Component12 className="component-12-instance" />
            <Component9 className="component-9-instance" />
            <Component10 className="component-10-instance" />
            <Component13
              className="component-13-instance"
              divClassName="component-3"
            />
            <Component11 className="component-11-instance" />
            <div className="overlap-wrapper">
              <div className="overlap-8">
                <div className="frame-31">
                  <p className="creating-fashion">
                    <span className="text-wrapper-53">
                      Creating Fashion that Inspires -{" "}
                    </span>
                    <span className="text-wrapper-54">
                      Discover the Artistry
                    </span>
                  </p>
                  <p className="hi-i-am-ranjitha-a">
                    Hi, I’am Ranjitha, A Fashion Stylist based in Bengaluru.
                    Graduated from Pearl Academy, I went from reading stories
                    like Wuthering heights and Kafka to envisioning how these
                    characters would look like if they were real. Quoting Witold
                    Marain &#34; To me art almost always speaks more forcefully
                    when it appears in an imperfect, accidental, and fragmentary
                    way, somehow just signaling it&#39;s presence&#34;. Art to
                    me may never be perfect because art is who you are, and
                    imperfections are what makes you.
                    <br />
                    <br />I have always pictured everything I read to make the
                    reading experience more interesting. From their clothes to
                    hair, their poise and posture, the scent of the place and
                    person, the texture of their skin, the touch and feel ,their
                    eyes and makeup, characters who are burning and exploding,
                    everything that would make the book more interesting. Images
                    have always inspired and moved me in ways that I
                    wouldn&#39;t have otherwise known. To me Image making is
                    bringing those memories alive. Images that aren&#39;t
                    necessarily perfect, but you have breathed life to the
                    nostalgia you envision.
                    <br />
                    <br />
                    My Culture and roots play a huge role in bringing these
                    images alive. The compilation of my work are stories that
                    are personal, the way of life and living, it’s people and
                    their stories that needs to be heard.
                  </p>
                </div>
                <Component14
                  MGCopyClassName="component-14-instance"
                  MGCopyClassNameOverride="component-4"
                  className="component-instance"
                  element="/img/mg-7565-copy-7.png"
                  elementClassName="component-5"
                  elementClassNameOverride="component-6"
                  img="/img/mg-7565-copy-7.png"
                  imgClassName="component-7"
                  property1="frame-162968"
                />
              </div>
            </div>
            <div className="frame-32">
              <p className="text-wrapper-55">Elevate Your Style with Vykhari</p>
              <p className="text-wrapper-56">
                Crafting Unique Fashion for Every Occasion.
              </p>
            </div>
            <img
              className="element-8"
              alt="Element"
              src="/img/dsc-6181-copy-1.png"
            />
            <div className="vector-wrapper">
              <img
                className="vector-3"
                alt="Vector"
                src="/img/mg-7565-copy-7.png"
              />
            </div>
            <div className="footers">
              <img
                className="vykhari-2"
                alt="Vykhari"
                src="/img/mg-7565-copy-7.png"
              />
              <div className="company-links">
                <div className="text-wrapper-57">Our Company</div>
                <div className="text-wrapper-58">Our Team</div>
                <div className="text-wrapper-59">Company Vision</div>
              </div>
              <div className="text-wrapper-60">Copyright 2022 Vykhari</div>
              <div className="resources-links">
                <div className="text-wrapper-57">Our Services</div>
                <div className="text-wrapper-58">Portfolio</div>
              </div>
              <div className="product-links">
                <div className="text-wrapper-57">Contact Us</div>
                <div className="text-wrapper-59">936728728382</div>
                <div className="text-wrapper-58">ranjhitha @gmail.com</div>
              </div>
              <img
                className="divider"
                alt="Divider"
                src="/img/dsc-6181-copy-1.png"
              />
            </div>
            <div className="overlap-group-wrapper">
              <div className="overlap-9">
                <div className="frame-33">
                  <div className="group-15">
                    <div className="here-s-how-THE-wrapper">
                      <p className="here-s-how-THE">
                        <span className="text-wrapper-61">Here’s how </span>
                        <span className="text-wrapper-62">THE SISTERS</span>
                        <span className="text-wrapper-61">
                          {" "}
                          are encouraging women to embrace their inner beauty
                          and passion.
                        </span>
                      </p>
                    </div>
                    <div className="group-16">
                      <p className="text-wrapper-63">
                        In collaboration with Vogue Magazine
                      </p>
                      <div className="text-wrapper-64">By - Simran Khanna</div>
                      <img
                        className="line-2"
                        alt="Line"
                        src="/img/line-2.png"
                      />
                    </div>
                  </div>
                  <div className="overlap-10">
                    <div className="group-17">
                      <div className="element-reasons-to-buy-wrapper">
                        <p className="element-reasons-to-buy">
                          <span className="text-wrapper-61">
                            4 Reasons to buy{" "}
                          </span>
                          <span className="text-wrapper-65">
                            Channapatna Toys
                          </span>
                          <span className="text-wrapper-61">
                            {" "}
                            for your kids in the modern world.
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="group-18">
                      <img
                        className="element-copy"
                        alt="Element copy"
                        src="/img/mask-group-1.png"
                      />
                      <div className="the-jewellery-INDIAN-wrapper">
                        <p className="the-jewellery-INDIAN">
                          <span className="text-wrapper-66">
                            The jewellery: <br />
                          </span>
                          <span className="text-wrapper-67">
                            INDIAN CULTURE
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="group-19">
                      <img
                        className="IMG-6"
                        alt="Img"
                        src="/img/img-5242-1-5.png"
                      />
                      <div className="text-wrapper-68">Ageing of garments</div>
                      <div className="group-20">
                        <div className="text-wrapper-28">Learn more</div>
                        <div className="ellipse-6" />
                      </div>
                    </div>
                  </div>
                  <img
                    className="mask-group"
                    alt="Mask group"
                    src="/img/mask-group-1.png"
                  />
                </div>
                <div className="group-21">
                  <div className="text-wrapper-28">Learn more</div>
                  <div className="ellipse-6" />
                </div>
                <div className="group-22">
                  <div className="text-wrapper-69">Learn more</div>
                  <div className="ellipse-7" />
                </div>
                <div className="group-23">
                  <div className="text-wrapper-28">Learn more</div>
                  <div className="ellipse-6" />
                </div>
              </div>
            </div>
          </div>
          <div className="frame-34">
            <div className="text-wrapper-70">Approach</div>
            <div className="ellipse-8" />
            <div className="ellipse-9" />
            <div className="ellipse-10" />
            <div className="ellipse-11" />
            <div className="ellipse-12" />
            <div className="ellipse-13" />
            <p className="visually-striking">
              Visually striking and modern user interface that&#39;s designed to
              leave a lasting impression. <br />
              With sleek aesthetics and a clean layout, navigating our site is a
              delightful experience.
              <br />
              <br />
              Engaging animations, videos, and interactive features are
              seamlessly integrated to provide an immersive and informative
              journey.
              <br />
              <br />
              We believe in the power of impactful visuals and compelling
              content. The website is adorned with bold and high-quality imagery
              that complements our content.
              <br />
              <br />
              User experience is at the core of our design philosophy. Website
              is intuitively structured, making it easy for visitors to find the
              information they seek.
              <br />
              <br />
              Ensuring a seamless and visually appealing experience on any
              device, be it a smartphone, tablet, or desktop computer.
              <br />
              <br />
              We&#39;ve optimized the website to ensure it ranks well on search
              engines.
            </p>
          </div>
        </div>
        <div className="overlap-11">
          <div className="frame-35">
            <div className="heading-wrapper">
              <div className="heading">
                <div className="text-wrapper-71">Vykhari</div>
                <p className="digital-branding-web">
                  Digital branding &amp; web design for a renowned fashion
                  stylist
                </p>
              </div>
            </div>
            <div className="group-24">
              <div className="frame-36">
                <div className="UIX">
                  <div className="frame-37">
                    <div className="frame-38">
                      <div className="group-25">
                        <div className="overlap-group-5">
                          <img
                            className="line-3"
                            alt="Line"
                            src="/img/line-18.svg"
                          />
                          <img
                            className="line-4"
                            alt="Line"
                            src="/img/line-19.svg"
                          />
                        </div>
                      </div>
                      <div className="text-wrapper-72">Fashion Styling</div>
                    </div>
                  </div>
                </div>
                <div className="UIX-2">
                  <div className="frame-37">
                    <div className="frame-38">
                      <div className="group-25">
                        <div className="overlap-group-5">
                          <img
                            className="line-3"
                            alt="Line"
                            src="/img/line-18.svg"
                          />
                          <img
                            className="line-4"
                            alt="Line"
                            src="/img/line-19.svg"
                          />
                        </div>
                      </div>
                      <div className="text-wrapper-72">Lifestyle</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="text-wrapper-73">Industry</div>
            </div>
          </div>
          <img
            className="macbook-air-mockup"
            alt="Macbook air mockup"
            src="/img/macbook-air-mockup-1-2.png"
          />
        </div>
        <div className="element-9">
          <div className="overlap-12">
            <div className="text-wrapper-74">Visual Language</div>
            <div className="text-wrapper-74">Visual Language</div>
          </div>
          <div className="frame-39">
            <div className="overlap-group-6">
              <div className="overlap-13">
                <div className="text-wrapper-75">Aa</div>
                <div className="text-wrapper-76">Charter</div>
              </div>
              <div className="text-wrapper-77">
                AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz
              </div>
            </div>
          </div>
          <div className="frame-40">
            <div className="frame-41">
              <div className="frame-42">
                <div className="frame-43">
                  <div className="text-wrapper-78">Forest Green</div>
                  <div className="frame-44">
                    <div className="text-wrapper-79">#143B35</div>
                  </div>
                </div>
              </div>
              <div className="frame-45">
                <div className="frame-46">
                  <div className="text-wrapper-78">Brown</div>
                  <div className="frame-44">
                    <div className="text-wrapper-79"> #774C24</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="frame-47">
              <div className="frame-48">
                <div className="frame-46">
                  <div className="text-wrapper-80">Mint</div>
                  <div className="frame-44">
                    <div className="text-wrapper-81">#C9F9DF</div>
                  </div>
                </div>
              </div>
              <div className="frame-49">
                <div className="frame-46">
                  <div className="text-wrapper-80">Beige</div>
                  <div className="frame-44">
                    <div className="text-wrapper-81">#FFF5E2</div>
                  </div>
                </div>
              </div>
              <div className="frame-50">
                <div className="frame-46">
                  <div className="text-wrapper-82">Dark Purple</div>
                  <div className="frame-44">
                    <div className="text-wrapper-83"> #1E131E</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="frame-51">
              <div className="frame-52">
                <div className="frame-46">
                  <div className="text-wrapper-82">Outer Space</div>
                  <div className="frame-44">
                    <div className="text-wrapper-83">#485058</div>
                  </div>
                </div>
              </div>
              <div className="frame-53">
                <div className="frame-46">
                  <div className="text-wrapper-82">Rich Black</div>
                  <div className="frame-44">
                    <div className="text-wrapper-83">#000000</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="overlap-14">
          <div className="service">
            <div className="div-3">
              <div className="div-3">
                <div className="overlap-group-7">
                  <div className="div-3">
                    <img
                      className="rectangle-10"
                      alt="Rectangle"
                      src="/img/rectangle-10.png"
                    />
                  </div>
                  <div className="frame-54">
                    <p className="editorial-fashion">
                      <span className="text-wrapper-84">Editorial </span>
                      <span className="text-wrapper-84">f</span>
                      <span className="text-wrapper-84">ashion Styling</span>
                    </p>
                    <p className="text-wrapper-85">
                      Style is the most impactful element to creating a lasting
                      impression.
                    </p>
                    <DivWrapper
                      className="component-16"
                      divClassName="component-8"
                      ellipseClassName="component-15"
                      groupClassName="component-25-instance"
                      property1="frame-162983"
                    />
                  </div>
                </div>
              </div>
              <div className="header-2">
                <img
                  className="vykhari-3"
                  alt="Vykhari"
                  src="/img/vykhari-1-2.png"
                />
                <div className="frame-55">
                  <div className="frame-56">
                    <div className="text-wrapper-86">About Us</div>
                  </div>
                  <div className="frame-57">
                    <div className="text-wrapper-87">Services</div>
                  </div>
                  <div className="frame-58">
                    <div className="text-wrapper-88">Work</div>
                  </div>
                  <div className="frame-59">
                    <div className="text-wrapper-89">Contact us</div>
                  </div>
                </div>
              </div>
            </div>
            <p className="text-wrapper-90">
              Why educators and families love kreedo
            </p>
            <div className="footers-2">
              <img
                className="vykhari-4"
                alt="Vykhari"
                src="/img/mg-7565-copy-7.png"
              />
              <div className="company-links-2">
                <div className="text-wrapper-91">Our Company</div>
                <div className="text-wrapper-92">Our Team</div>
                <div className="text-wrapper-93">Company Vision</div>
              </div>
              <div className="text-wrapper-94">Copyright 2022 Vykhari</div>
              <div className="resources-links-2">
                <div className="text-wrapper-91">Our Services</div>
                <div className="text-wrapper-92">Portfolio</div>
              </div>
              <div className="product-links-2">
                <div className="text-wrapper-91">Contact Us</div>
                <div className="text-wrapper-93">936728728382</div>
                <div className="text-wrapper-92">ranjhitha @gmail.com</div>
              </div>
              <img
                className="divider-2"
                alt="Divider"
                src="/img/dsc-6181-copy-1.png"
              />
            </div>
            <div className="group-26">
              <img
                className="DSC-copy-7"
                alt="Dsc copy"
                src="/img/dsc-6181-copy-1.png"
              />
              <div className="frame-60">
                <p className="text-wrapper-95">
                  Get the perfect drapes of fashion with us
                </p>
                <div className="frame-61">
                  <div className="text-wrapper-96">Let’s Connect</div>
                </div>
              </div>
            </div>
            <div className="overlap-15">
              <img
                className="rectangle-11"
                alt="Rectangle"
                src="/img/rectangle-7.svg"
              />
              <img
                className="screenshot"
                alt="Screenshot"
                src="/img/screenshot-2023-04-06-at-11-51-1.png"
              />
              <img className="image-2" alt="Image" src="/img/image-7.png" />
              <img
                className="rectangle-12"
                alt="Rectangle"
                src="/img/rectangle-8.png"
              />
              <img
                className="rectangle-13"
                alt="Rectangle"
                src="/img/rectangle-9.png"
              />
              <Component26
                className="component-26-instance"
                groupClassName="component-17"
                property1="frame-162990"
                weArePassionateClassName="component-23"
                weCollaborateClassName="component-20"
                weConceptualiseClassName="component-18"
                weCreateClassName="component-21"
                weCurateClassName="component-19"
              />
              <div className="group-27">
                <div className="text-wrapper-97">Contact us</div>
                <div className="ellipse-14" />
              </div>
            </div>
            <div className="group-wrapper">
              <div className="group-28">
                <img className="vector-4" alt="Vector" src="/img/vector.svg" />
              </div>
            </div>
            <div className="overlap-16">
              <div className="overlap-17">
                <div className="group-29">
                  <div className="overlap-18">
                    <img className="HN-2" alt="Hn" src="/img/hn-08826-1.png" />
                    <div className="frame-62">
                      <div className="MEN-s-STYLING">
                        MEN’S STYLING
                        <br />
                        2021
                      </div>
                      <div className="group-30">
                        <div className="text-wrapper-98">Learn more</div>
                        <div className="ellipse-15" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="overlap-19">
                  <div className="group-31">
                    <div className="overlap-20">
                      <div className="text-wrapper-99">PRODUCT STYLING</div>
                      <div className="group-32">
                        <div className="text-wrapper-100">Learn more</div>
                        <div className="ellipse-16" />
                      </div>
                      <div className="ellipse-17" />
                    </div>
                  </div>
                  <div className="element-10" />
                  <div className="group-33">
                    <div className="text-wrapper-101">Learn more</div>
                    <div className="ellipse-18" />
                  </div>
                  <p className="e-COMMERCE-styling">
                    <span className="text-wrapper-102">E-C</span>
                    <span className="text-wrapper-102">OMMERCE </span>
                    <span className="text-wrapper-102">Styling</span>
                  </p>
                  <div className="text-wrapper-103">01</div>
                  <div className="text-wrapper-104">02</div>
                  <div className="text-wrapper-105">03</div>
                  <img
                    className="element-11"
                    alt="Element"
                    src="/img/18-2.png"
                  />
                  <img
                    className="element-12"
                    alt="Element"
                    src="/img/9-1.png"
                  />
                  <img
                    className="element-13"
                    alt="Element"
                    src="/img/9-2.png"
                  />
                  <div className="rectangle-14" />
                  <div className="frame-63">
                    <img
                      className="rectangle-15"
                      alt="Rectangle"
                      src="/img/rectangle-25.png"
                    />
                    <img
                      className="rectangle-16"
                      alt="Rectangle"
                      src="/img/rectangle-25.png"
                    />
                    <img
                      className="rectangle-17"
                      alt="Rectangle"
                      src="/img/rectangle-25.png"
                    />
                    <img
                      className="rectangle-18"
                      alt="Rectangle"
                      src="/img/rectangle-25.png"
                    />
                    <img
                      className="rectangle-19"
                      alt="Rectangle"
                      src="/img/rectangle-25.png"
                    />
                  </div>
                  <p className="text-wrapper-106">
                    Soak a white cloth for a coffee soiree
                  </p>
                </div>
              </div>
              <div className="group-34">
                <div className="overlap-21">
                  <div className="group-35">
                    <div className="text-wrapper-107">Learn more</div>
                    <div className="ellipse-19" />
                  </div>
                  <div className="overlap-group-8">
                    <div className="text-wrapper-108">Garment Ageing</div>
                    <p className="text-wrapper-109">
                      Soak a white cloth for a coffee soiree
                    </p>
                  </div>
                  <div className="text-wrapper-110">Fabric Experimentation</div>
                </div>
              </div>
            </div>
            <div className="frame-64">
              <div className="frame-65">
                <div className="discover-more">Discover More</div>
                <p className="text-wrapper-111">
                  Discover Your Style Personality and Transform Your Look with
                  Personal Styling.
                </p>
              </div>
              <div className="frame-66">
                <div className="text-wrapper-112">Let’s Connect</div>
              </div>
            </div>
            <Component33
              className="component-33-instance"
              component27DscCopy="/img/dsc-6340-copy-1.png"
              component27DscCopyClassName="component-29"
              component27DscCopyClassNameOverride="component-30"
              component27Img="/img/dsc-6267-copy-1.png"
              component27PropertyFrameClassName="component-28"
              divClassName="component-34"
              divClassName1="component-37"
              divClassName2="component-41"
              divClassName3="component-41"
              divClassName4="component-41"
              divClassName5="component-41"
              divClassName6="component-41"
              divClassNameOverride="component-35"
              ellipseClassName="component-38"
              frameClassName="component-31"
              frameClassName1="component-39"
              frameClassName2="component-40"
              frameClassName3="component-42"
              frameClassName4="component-42"
              frameClassName5="component-42"
              frameClassName6="component-42"
              frameClassNameOverride="component-32"
              groupClassName="component-36"
              overlapGroupClassName="component-24"
              property1="frame-163022"
            />
          </div>
          <div className="work">
            <div className="overlap-22">
              <div className="frame-67">
                <div className="text-wrapper-113">Brand Collaborations</div>
                <div className="frame-68">
                  <img
                    className="image-3"
                    alt="Image"
                    src="/img/image-14.png"
                  />
                  <img
                    className="image-4"
                    alt="Image"
                    src="/img/image-14.png"
                  />
                  <img
                    className="image-5"
                    alt="Image"
                    src="/img/image-14.png"
                  />
                  <img
                    className="image-6"
                    alt="Image"
                    src="/img/image-14.png"
                  />
                </div>
              </div>
            </div>
            <div className="overlap-23">
              <Component1
                DSCCopy="/img/dsc-6340-copy-3.png"
                DSCCopyClassName="component-43"
                DSCCopyClassNameOverride="component-45"
                IMG="/img/img-5892-1.png"
                IMG1="/img/img-5892-2.png"
                IMG2="/img/img-5892-3.png"
                IMG3="/img/img-5892-4.png"
                IMGClassName="component-46"
                IMGClassNameOverride="component-47"
                className="component-1-instance"
                divClassName="component-52"
                divClassNameOverride="component-53"
                frameClassName="component-50"
                garmentAgeingClassName="component-51"
                img="/img/dsc-6340-copy-2.png"
                imgClassName="component-48"
                imgClassNameOverride="component-49"
                overlapGroupClassName="component-43"
                property1="frame-163004"
                rectangleClassName="component-44"
              />
              <div className="header-3">
                <img
                  className="vykhari-5"
                  alt="Vykhari"
                  src="/img/vykhari-1-1.png"
                />
                <div className="frame-69">
                  <div className="frame-70">
                    <div className="text-wrapper-114">About Us</div>
                  </div>
                  <div className="frame-71">
                    <div className="text-wrapper-115">Services</div>
                  </div>
                  <div className="frame-72">
                    <div className="text-wrapper-116">Work</div>
                  </div>
                  <div className="frame-73">
                    <div className="text-wrapper-117">Contact us</div>
                  </div>
                </div>
              </div>
            </div>
            <p className="text-wrapper-118">
              Why educators and families love kreedo
            </p>
            <div className="group-36">
              <div className="text-wrapper-119">Load more</div>
              <div className="ellipse-20" />
            </div>
            <div className="footers-3">
              <img
                className="vykhari-6"
                alt="Vykhari"
                src="/img/image-14.png"
              />
              <div className="company-links-3">
                <div className="text-wrapper-120">Our Company</div>
                <div className="text-wrapper-121">Our Team</div>
                <div className="text-wrapper-122">Company Vision</div>
              </div>
              <div className="text-wrapper-123">Copyright 2022 Vykhari</div>
              <div className="resources-links-3">
                <div className="text-wrapper-120">Our Services</div>
                <div className="text-wrapper-121">Portfolio</div>
              </div>
              <div className="product-links-3">
                <div className="text-wrapper-120">Contact Us</div>
                <div className="text-wrapper-122">936728728382</div>
                <div className="text-wrapper-121">ranjhitha @gmail.com</div>
              </div>
              <img className="divider-3" alt="Divider" src="/img/divider.png" />
            </div>
            <div className="frame-74">
              <div className="frame-75">
                <div className="frame-76">
                  <div className="frame-77">
                    <div className="text-wrapper-124">EDITORIAL SHOOT</div>
                  </div>
                  <div className="frame-78">
                    <div className="text-wrapper-125">Channapatna Toys</div>
                  </div>
                </div>
                <p className="text-wrapper-126">Born with a wooden spoon</p>
              </div>
              <div className="frame-79">
                <img
                  className="rectangle-20"
                  alt="Rectangle"
                  src="/img/rectangle-31-6.png"
                />
                <img
                  className="rectangle-21"
                  alt="Rectangle"
                  src="/img/rectangle-32-6.png"
                />
                <img
                  className="rectangle-20"
                  alt="Rectangle"
                  src="/img/rectangle-33-6.png"
                />
              </div>
              <img
                className="rectangle-22"
                alt="Rectangle"
                src="/img/rectangle-34-1.png"
              />
              <img
                className="rectangle-23"
                alt="Rectangle"
                src="/img/rectangle-34.png"
              />
              <div className="frame-80">
                <div className="frame-76">
                  <div className="frame-77">
                    <div className="text-wrapper-124">EDITORIAL SHOOT</div>
                  </div>
                  <div className="frame-78">
                    <div className="text-wrapper-125">Rasum</div>
                  </div>
                </div>
                <p className="text-wrapper-126">
                  A day in the life of two sisters
                </p>
              </div>
              <div className="frame-81">
                <img
                  className="rectangle-20"
                  alt="Rectangle"
                  src="/img/rectangle-31-5.png"
                />
                <img
                  className="rectangle-21"
                  alt="Rectangle"
                  src="/img/rectangle-32-5.png"
                />
                <img
                  className="rectangle-20"
                  alt="Rectangle"
                  src="/img/rectangle-33-5.png"
                />
              </div>
              <img
                className="rectangle-24"
                alt="Rectangle"
                src="/img/rectangle-35.png"
              />
              <img
                className="rectangle-25"
                alt="Rectangle"
                src="/img/rectangle-36.png"
              />
              <div className="frame-82">
                <div className="frame-76">
                  <div className="frame-77">
                    <div className="text-wrapper-124">COMMERCIAL SHOOT</div>
                  </div>
                  <div className="frame-78">
                    <div className="text-wrapper-125">Garment Ageing</div>
                  </div>
                </div>
                <div className="text-wrapper-126">
                  Way of Fabric manipulation
                </div>
              </div>
              <div className="frame-83">
                <img
                  className="rectangle-20"
                  alt="Rectangle"
                  src="/img/rectangle-31-4.png"
                />
                <img
                  className="rectangle-21"
                  alt="Rectangle"
                  src="/img/rectangle-32-4.png"
                />
                <img
                  className="rectangle-20"
                  alt="Rectangle"
                  src="/img/rectangle-33-4.png"
                />
              </div>
              <img
                className="rectangle-26"
                alt="Rectangle"
                src="/img/rectangle-37.png"
              />
              <img
                className="rectangle-27"
                alt="Rectangle"
                src="/img/rectangle-38.png"
              />
              <div className="frame-84">
                <div className="frame-76">
                  <div className="frame-77">
                    <div className="text-wrapper-124">EDITORIAL SHOOT</div>
                  </div>
                  <div className="frame-78">
                    <div className="text-wrapper-125">House Of Three</div>
                  </div>
                </div>
                <p className="text-wrapper-126">The real story of Dravidians</p>
              </div>
              <div className="frame-85">
                <img
                  className="rectangle-28"
                  alt="Rectangle"
                  src="/img/rectangle-31-3.png"
                />
                <img
                  className="rectangle-29"
                  alt="Rectangle"
                  src="/img/rectangle-32-3.png"
                />
                <img
                  className="rectangle-28"
                  alt="Rectangle"
                  src="/img/rectangle-33-3.png"
                />
              </div>
              <img
                className="MG-copy-4"
                alt="Mg copy"
                src="/img/mg-7565-copy-1.png"
              />
              <div className="frame-86">
                <div className="frame-76">
                  <div className="frame-77">
                    <div className="text-wrapper-124">COMMERCIAL SHOOT</div>
                  </div>
                  <div className="frame-78">
                    <div className="text-wrapper-125">Men &amp; Makeup</div>
                  </div>
                </div>
                <div className="text-wrapper-126">Breaking the taboo</div>
              </div>
              <div className="frame-87">
                <img
                  className="rectangle-30"
                  alt="Rectangle"
                  src="/img/mg-7565-copy-7.png"
                />
                <img
                  className="rectangle-31"
                  alt="Rectangle"
                  src="/img/mg-7565-copy-7.png"
                />
                <img
                  className="rectangle-32"
                  alt="Rectangle"
                  src="/img/mg-7565-copy-7.png"
                />
              </div>
              <img
                className="MG-copy-5"
                alt="Mg copy"
                src="/img/mg-7565-copy-7.png"
              />
              <img
                className="MG-copy-6"
                alt="Mg copy"
                src="/img/mg-7565-copy-7.png"
              />
              <div className="frame-88">
                <div className="frame-76">
                  <div className="frame-77">
                    <div className="text-wrapper-124">EDITORIAL SHOOT</div>
                  </div>
                  <div className="frame-78">
                    <div className="text-wrapper-125">Dark Academia</div>
                  </div>
                </div>
                <p className="text-wrapper-126">
                  A dive into European Subculture
                </p>
              </div>
              <div className="frame-89">
                <img
                  className="rectangle-33"
                  alt="Rectangle"
                  src="/img/mg-7565-copy-7.png"
                />
                <img
                  className="rectangle-34"
                  alt="Rectangle"
                  src="/img/mg-7565-copy-7.png"
                />
                <img
                  className="rectangle-35"
                  alt="Rectangle"
                  src="/img/mg-7565-copy-7.png"
                />
              </div>
              <img
                className="MG-copy-7"
                alt="Mg copy"
                src="/img/mg-7565-copy-7.png"
              />
              <img
                className="MG-copy-8"
                alt="Mg copy"
                src="/img/mg-7565-copy-7.png"
              />
              <div className="frame-90">
                <div className="frame-76">
                  <div className="frame-77">
                    <div className="text-wrapper-124">EDITORIAL SHOOT</div>
                  </div>
                  <div className="frame-78">
                    <div className="text-wrapper-125">Indira Gandhi</div>
                  </div>
                </div>
                <div className="text-wrapper-126">
                  Costume Design and Replication
                </div>
              </div>
              <div className="frame-91">
                <img
                  className="rectangle-36"
                  alt="Rectangle"
                  src="/img/mg-7565-copy-7.png"
                />
                <img
                  className="rectangle-37"
                  alt="Rectangle"
                  src="/img/mg-7565-copy-7.png"
                />
                <img
                  className="rectangle-38"
                  alt="Rectangle"
                  src="/img/mg-7565-copy-7.png"
                />
              </div>
              <img
                className="MG-copy-9"
                alt="Mg copy"
                src="/img/mg-7565-copy-7.png"
              />
              <img
                className="MG-copy-10"
                alt="Mg copy"
                src="/img/mg-7565-copy-7.png"
              />
            </div>
          </div>
          <img className="element-14" alt="Element" src="/img/186-1.png" />
        </div>
        <div className="mask-group-wrapper">
          <img
            className="mask-group-2"
            alt="Mask group"
            src="/img/mask-group.png"
          />
        </div>
        <div className="frame-92">
          <p className="element-delivered">
            99xStudio delivered a highly interactive and engaging website that
            conveyed a seamless and captivating experience. The process was
            smooth, as 99xStudio was efficient, coordinated, and highly
            skilled.&#34;
          </p>
          <div className="frame-93">
            <div className="frame-94">
              <div className="frame-95">
                <div className="text-wrapper-127">Mithun M Jagadeesh</div>
                <div className="text-wrapper-128">CMO, Vykhari</div>
              </div>
            </div>
            <a
              className="text-wrapper-129"
              href="https://clutch.co/profile/99xstudio#review-2198693"
              rel="noopener noreferrer"
              target="_blank"
            >
              Read the complete review
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
