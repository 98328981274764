/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";
import "./style.css";

export const Component13 = ({ className, divClassName }) => {
  return (
    <div className={`component-13 ${className}`}>
      <div className={`text-wrapper-10 ${divClassName}`}>Dark Academia</div>
      <div className="text-wrapper-11">Subculture</div>
    </div>
  );
};
