/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { useReducer } from "react";
import "./style.css";

export const Component27 = ({
  property1,
  className,
  DSCCopyClassName,
  DSCCopy = "/img/dsc-6267-copy-1-2.png",
  DSCCopyClassNameOverride,
  img = "/img/dsc-6340-copy-1-2.png",
}) => {
  const [state, dispatch] = useReducer(reducer, {
    property1: property1 || "frame-162983",
  });

  return (
    <div
      className={`component-27 ${className}`}
      onMouseLeave={() => {
        dispatch("mouse_leave");
      }}
      onMouseEnter={() => {
        dispatch("mouse_enter");
      }}
    >
      <img
        className={`DSC-copy ${DSCCopyClassName}`}
        alt="Dsc copy"
        src={state.property1 === "frame-162984" ? "/img/dsc-6267-copy-1-1.png" : DSCCopy}
      />
      <img
        className={`DSC-copy-2 ${DSCCopyClassNameOverride}`}
        alt="Dsc copy"
        src={state.property1 === "frame-162984" ? "/img/dsc-6340-copy-1-1.png" : img}
      />
    </div>
  );
};

function reducer(state, action) {
  switch (action) {
    case "mouse_enter":
      return {
        ...state,
        property1: "frame-162984",
      };

    case "mouse_leave":
      return {
        ...state,
        property1: "frame-162983",
      };
  }

  return state;
}

Component27.propTypes = {
  property1: PropTypes.oneOf(["frame-162983", "frame-162984"]),
  DSCCopy: PropTypes.string,
  img: PropTypes.string,
};
