/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const Component14 = ({
  property1,
  className,
  MGCopyClassName,
  MGCopyClassNameOverride,
  elementClassName,
  elementClassNameOverride,
  element = "/img/6-31640322-2.png",
  imgClassName,
  img = "/img/6-31640322-2.png",
}) => {
  return (
    <div className={`component-14 ${className}`}>
      <div className={`group-5 ${property1}`}>
        <img
          className="HN"
          alt="Hn"
          src={property1 === "frame-162969" ? "/img/hn-01546-1-1.png" : "/img/hn-01546-1-2.png"}
        />
        <img
          className="MG-copy"
          alt="Mg copy"
          src={property1 === "frame-162969" ? "/img/mg-7905-copy-3-1.png" : "/img/mg-7905-copy-2-2.png"}
        />
        <img
          className={`MG-copy-2 ${MGCopyClassName}`}
          alt="Mg copy"
          src={property1 === "frame-162969" ? "/img/mg-7905-copy-3-1.png" : "/img/6-31640326-2.png"}
        />
        <img
          className={`MG-copy-3 ${MGCopyClassNameOverride}`}
          alt="Mg copy"
          src={property1 === "frame-162969" ? "/img/mg-7905-copy-4-1.png" : "/img/6-31640326-2.png"}
        />
        <img
          className={`element ${elementClassName}`}
          alt="Element"
          src={property1 === "frame-162969" ? "/img/6-31640326-1.png" : "/img/6-31640326-2.png"}
        />
        <img
          className="element-2"
          alt="Element"
          src={property1 === "frame-162969" ? "/img/6-31640324-1.png" : "/img/6-31640324-2.png"}
        />
      </div>
      <div className={`group-6 property-1-3-${property1}`}>
        <img
          className="element-3"
          alt="Element"
          src={property1 === "frame-162969" ? "/img/6-31640320-1.png" : "/img/6-31640320-2.png"}
        />
        <img
          className="element-4"
          alt="Element"
          src={property1 === "frame-162969" ? "/img/6-31640323-1.png" : "/img/6-31640323-2.png"}
        />
        <img
          className="element-5"
          alt="Element"
          src={property1 === "frame-162969" ? "/img/6-31640325-1.png" : "/img/6-31640325-2.png"}
        />
        <img
          className={`element-6 ${elementClassNameOverride}`}
          alt="Element"
          src={property1 === "frame-162969" ? "/img/6-31640321-1.png" : element}
        />
        <img
          className={`element-7 ${imgClassName}`}
          alt="Element"
          src={property1 === "frame-162969" ? "/img/6-31640322-1.png" : img}
        />
      </div>
    </div>
  );
};

Component14.propTypes = {
  property1: PropTypes.oneOf(["frame-162969", "frame-162968"]),
  element: PropTypes.string,
  img: PropTypes.string,
};
