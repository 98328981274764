/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const Component26 = ({
  property1,
  className,
  groupClassName,
  weConceptualiseClassName,
  weCurateClassName,
  weCollaborateClassName,
  weCreateClassName,
  weArePassionateClassName,
}) => {
  return (
    <div className={`component-26 ${className}`}>
      <div className={`group-7 ${property1} ${groupClassName}`}>
        <p className={`we-conceptualise ${weConceptualiseClassName}`}>
          <span className="span">We </span>
          <span className="text-wrapper-14">Conceptualise</span>
        </p>
        <p className={`we-curate ${weCurateClassName}`}>
          <span className="span">We </span>
          <span className="text-wrapper-14">Curate</span>
        </p>
        <p className={`we-collaborate ${weCollaborateClassName}`}>
          <span className="span">We </span>
          <span className="text-wrapper-14">Collaborate</span>
        </p>
        <p className={`we-create ${weCreateClassName}`}>
          <span className="span">We </span>
          <span className="text-wrapper-14">Create</span>
        </p>
        <p className={`we-are-passionate ${weArePassionateClassName}`}>
          <span className="span">We are </span>
          <span className="text-wrapper-14">Passionate</span>
        </p>
      </div>
    </div>
  );
};

Component26.propTypes = {
  property1: PropTypes.oneOf(["frame-162993", "frame-162990", "frame-162994", "frame-162991", "frame-162992"]),
};
