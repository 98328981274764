/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";
import "./style.css";

export const Component10 = ({ className }) => {
  return (
    <div className={`component-10 ${className}`}>
      <p className="text-wrapper-8">A day in the life of two sisters</p>
      <div className="text-wrapper-9">Editorial Shoot</div>
    </div>
  );
};
