/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { useReducer } from "react";
import { Component27 } from "../Component27";
import "./style.css";

export const Component33 = ({
  property1,
  className,
  overlapGroupClassName,
  component27PropertyFrameClassName,
  component27DscCopyClassName,
  component27DscCopy = "/img/dsc-6340-copy-1-2.png",
  component27DscCopyClassNameOverride,
  component27Img = "/img/dsc-6267-copy-1-2.png",
  frameClassName,
  frameClassNameOverride,
  divClassName,
  divClassNameOverride,
  groupClassName,
  divClassName1,
  ellipseClassName,
  frameClassName1,
  frameClassName2,
  divClassName2,
  frameClassName3,
  divClassName3,
  frameClassName4,
  divClassName4,
  frameClassName5,
  divClassName5,
  frameClassName6,
  divClassName6,
}) => {
  const [state, dispatch] = useReducer(reducer, {
    property1: property1 || "frame-163022",
  });

  return (
    <div className={`component-33 ${className}`}>
      <div className={`overlap-group-2 ${overlapGroupClassName}`}>
        {state.property1 === "frame-163022" && (
          <Component27
            DSCCopy={component27Img}
            DSCCopyClassName={component27DscCopyClassNameOverride}
            DSCCopyClassNameOverride={component27DscCopyClassName}
            className={component27PropertyFrameClassName}
            img={component27DscCopy}
            property1="frame-162983"
          />
        )}

        {["frame-163023", "frame-163024", "frame-163025", "frame-163026"].includes(state.property1) && (
          <div className="component-2">
            <img
              className="DSC-copy-3"
              alt="Dsc copy"
              src={
                state.property1 === "frame-163024"
                  ? "/img/dsc-6267-copy-1-5.png"
                  : state.property1 === "frame-163025"
                  ? "/img/dsc-6267-copy-1-4.png"
                  : state.property1 === "frame-163026"
                  ? "/img/dsc-6267-copy-1-3.png"
                  : "/img/dsc-6267-copy-1-6.png"
              }
            />
            <img
              className="DSC-copy-4"
              alt="Dsc copy"
              src={
                state.property1 === "frame-163024"
                  ? "/img/dsc-6340-copy-1-5.png"
                  : state.property1 === "frame-163025"
                  ? "/img/dsc-6340-copy-1-4.png"
                  : state.property1 === "frame-163026"
                  ? "/img/dsc-6340-copy-1-3.png"
                  : "/img/dsc-6340-copy-1-6.png"
              }
            />
          </div>
        )}

        <div className={`frame-2 ${frameClassName}`}>
          <div className={`frame-3 ${frameClassNameOverride}`}>
            <div className={`editorial-styling ${divClassName}`}>
              {state.property1 === "frame-163022" && <>Editorial Styling</>}

              {state.property1 === "frame-163023" && <>Commercial Styling</>}

              {state.property1 === "frame-163024" && <>Personal Styling</>}

              {state.property1 === "frame-163025" && <>Product Styling</>}

              {state.property1 === "frame-163026" && <>Traditional Hindu Wedding</>}
            </div>
            <div className={`editorialist-fuses ${state.property1} ${divClassNameOverride}`}>
              {state.property1 === "frame-163022" && (
                <p className="text-wrapper-130">
                  Editorialist fuses personal styling, content, and shopping in one seamless digital experience driven
                  by proprietary technology, e-commerce tools, and luxury fashion content.
                </p>
              )}

              {state.property1 === "frame-163023" && (
                <p className="text-wrapper-130">
                  Elevate your online store with our Commercial Styling and E-commerce services. From concept to
                  photography, we create stunning visuals that drive sales.
                </p>
              )}

              {state.property1 === "frame-163024" && (
                <p className="text-wrapper-130">
                  We create bespoke looks that reflect your individuality. Styling for Weddings, Parties or any
                  occasion.
                </p>
              )}

              {state.property1 === "frame-163025" && (
                <p className="text-wrapper-130">
                  Concept to photography, we create visually stunning imagery that captivates your audience.
                </p>
              )}

              {state.property1 === "frame-163026" && (
                <p className="text-wrapper-130">
                  Add grandeur to your special day. From timeless sarees to regal sherwanis, we create bespoke ensembles
                  that reflect your unique style.
                </p>
              )}
            </div>
          </div>
          <div className={`group-8 ${groupClassName}`}>
            <div className={`text-wrapper-15 ${divClassName1}`}>Check out our portfolio</div>
            <div className={`ellipse-3 ${ellipseClassName}`} />
          </div>
        </div>
      </div>
      <div className={`frame-4 ${frameClassName1}`}>
        <div
          className={`frame-5 property-1-${state.property1} ${frameClassName2}`}
          onClick={() => {
            dispatch("click_672");
          }}
        >
          <div className={`text-wrapper-16 ${divClassName2}`}>Editorial Styling</div>
        </div>
        <div
          className={`frame-6 property-1-${state.property1} ${frameClassName3}`}
          onClick={() => {
            dispatch("click");
          }}
        >
          <div className={`text-wrapper-17 ${divClassName3}`}>Commercial Styling</div>
        </div>
        <div
          className={`frame-7 property-1-${state.property1} ${frameClassName4}`}
          onClick={() => {
            dispatch("click_653");
          }}
        >
          <div className={`text-wrapper-18 ${divClassName4}`}>Personal Styling</div>
        </div>
        <div
          className={`frame-8 property-1-${state.property1} ${frameClassName5}`}
          onClick={() => {
            dispatch("click_655");
          }}
        >
          <div className={`text-wrapper-19 ${divClassName5}`}>Product Styling</div>
        </div>
        <div
          className={`frame-9 property-1-${state.property1} ${frameClassName6}`}
          onClick={() => {
            dispatch("click_657");
          }}
        >
          <div className={`text-wrapper-20 ${divClassName6}`}>Traditional Hindu Wedding</div>
        </div>
      </div>
    </div>
  );
};

function reducer(state, action) {
  switch (action) {
    case "click":
      return {
        ...state,
        property1: "frame-163023",
      };

    case "click_653":
      return {
        ...state,
        property1: "frame-163024",
      };

    case "click_655":
      return {
        ...state,
        property1: "frame-163025",
      };

    case "click_657":
      return {
        ...state,
        property1: "frame-163026",
      };

    case "click_672":
      return {
        ...state,
        property1: "frame-163022",
      };
  }

  return state;
}

Component33.propTypes = {
  property1: PropTypes.oneOf(["frame-163024", "frame-163026", "frame-163022", "frame-163025", "frame-163023"]),
  component27DscCopy: PropTypes.string,
  component27Img: PropTypes.string,
};
