/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { useReducer } from "react";
import "./style.css";

export const DivWrapper = ({ property1, className, groupClassName, divClassName, ellipseClassName }) => {
  const [state, dispatch] = useReducer(reducer, {
    property1: property1 || "frame-162983",
  });

  return (
    <div
      className={`div-wrapper ${className}`}
      onMouseLeave={() => {
        dispatch("mouse_leave");
      }}
      onMouseEnter={() => {
        dispatch("mouse_enter");
      }}
    >
      <div className={`group-4 ${groupClassName}`}>
        <div className={`text-wrapper-4 ${state.property1} ${divClassName}`}>Our Portfolio</div>
        {state.property1 === "frame-162983" && <div className={`ellipse-2 ${ellipseClassName}`} />}

        {state.property1 === "frame-162984" && (
          <img className={`img ${ellipseClassName}`} alt="Ellipse" src="/img/ellipse-3.png" />
        )}
      </div>
    </div>
  );
};

function reducer(state, action) {
  switch (action) {
    case "mouse_enter":
      return {
        ...state,
        property1: "frame-162984",
      };

    case "mouse_leave":
      return {
        ...state,
        property1: "frame-162983",
      };
  }

  return state;
}

DivWrapper.propTypes = {
  property1: PropTypes.oneOf(["frame-162983", "frame-162984"]),
};
