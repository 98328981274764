/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { useReducer } from "react";
import "./style.css";

export const PropertyComponentWrapper = ({
  property1,
  className,
  rectangleClassName,
  rectangle = "/img/rectangle-387-2.png",
}) => {
  const [state, dispatch] = useReducer(reducer, {
    property1: property1 || "component-25",
  });

  return (
    <div
      className={`property-component-wrapper property-1-${state.property1} ${className}`}
      onMouseLeave={() => {
        dispatch("mouse_leave");
      }}
      onMouseEnter={() => {
        dispatch("mouse_enter");
      }}
    >
      {state.property1 === "component-25" && (
        <img className={`rectangle ${rectangleClassName}`} alt="Rectangle" src={rectangle} />
      )}

      {state.property1 === "component-26" && (
        <div className="overlap-group">
          <div className="rectangle-wrapper">
            <img className="rectangle" alt="Rectangle" src="/img/rectangle-387-1.png" />
          </div>
          <div className="div" />
          <div className="group">
            <div className="group-2">
              <div className="frame">
                <p className="text-wrapper">
                  We understand the power of visual storytelling and are dedicated to creating editorial content that
                  captivates and inspires. Our team of talented stylists, photographers, and creative directors work
                  collaboratively to produce editorial content that is both beautiful and thought-provoking.
                </p>
              </div>
              <div className="text-wrapper-2">Editorial Styling</div>
              <img className="vector" alt="Vector" src="/img/vector-1-2.svg" />
              <div className="group-3">
                <div className="text-wrapper-3">Learn more</div>
                <div className="ellipse" />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

function reducer(state, action) {
  switch (action) {
    case "mouse_enter":
      return {
        ...state,
        property1: "component-26",
      };

    case "mouse_leave":
      return {
        ...state,
        property1: "component-25",
      };
  }

  return state;
}

PropertyComponentWrapper.propTypes = {
  property1: PropTypes.oneOf(["component-26", "component-25"]),
  rectangle: PropTypes.string,
};
