/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const PropertyFrameWrapper = ({ property1, className }) => {
  return <div className={`property-frame-wrapper ${property1} ${className}`} />;
};

PropertyFrameWrapper.propTypes = {
  property1: PropTypes.oneOf(["frame-162972", "frame-162974", "frame-162973"]),
};
